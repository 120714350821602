
$primary: #0874D9;
$primary-active: darken( $primary, 5% );

$primary-accent: adjust-hue(lighten( $primary, 22% ),-15%);

$dark: desaturate(darken( $primary, 28% ), 30% );
$dark-active: darken( $dark, 5% );

$background: #f7f8f9;

$error: #E02200;
$errorBackground: lighten( $error, 26% );
$errorLight: rgba( $error, .1 );

$success: #2CA52C;
$successBackground: lighten( $success, 26% );
$successLight: rgba( $success, .13 );

$warning: #ff9a3c;
$warningBackground: lighten( $warning, 26% );
$warningLight: rgba( $warning, .13 );

// $primary-active: #007add;
$box-shadow:  0 2px 4px 0 rgba(0, 0, 0, 0.03);
$box-shadow-card: 1px 1px 5px 0 rgba(50,50,93,0.06);


:export{primary: $primary}

:export {
    primary: $primary;
    primaryActive: $primary-active;
    primaryAccent: $primary-accent;
    dark: $dark;
    darkActive: $dark-active;
    error: $error;
    errorBackground: $errorBackground;
    errorLight: $errorLight;
    success:$success;
    successBackground: $successBackground;
    successLight: $successLight;
    warning: $warning;
    warningBackground: $warningBackground;
    warningLight: $warningLight;
    boxShadow: $box-shadow;
    boxShadowCard: $box-shadow-card;
  }








body.tabbar-active {
    padding-bottom: 3.5em;
}
 
// .form-control {
//     border: none!important;
//     box-shadow: $box-shadow;
// }

.form-control {
    border: 1px solid #e0e0e0!important;
    box-shadow: none!important;
}
.form-control:focus, .form-control:active, .form-control:focus-within {
    border: 1px solid $primary!important;
    box-shadow: 0 0 0 1px $primary!important;
}



/*-----------------------
Add New Material
------------------------*/
.hasAddon_before .addOn {
    position: absolute;
    top: 8px;
    left: 8px;
    color: #666;
}
.hasAddon_before {
    position: relative;
    height: 100%;
}
.hasAddon_before input {
    padding-left: 20px;
}
.hasAddon_after .addOn {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #666;
}
.hasAddon_after {
    position: relative;
    height: 100%;
}
.hasAddon_before input {
    padding-right: 20px;
}


.input-group-append .input-group-text {
    background-color: #ffffff;
    border: 1.5px solid #eaeaea!important;
    border-radius: 0 .25rem .25rem 0;
    font-weight: 700;
    font-size: 1em;
    padding: .25em .5em;
    border-left: 0!important;
    
}
.input-group-prepend .input-group-text {
    background-color: #ffffff;
    border: 1.5px solid #eaeaea!important;
    border-radius: .25rem 0 0 .25rem;
    font-weight: 700;
    padding: .25em .5em;
    font-size: 1em;
    border-right:none;
    border-right: 0!important;
}

.form-section {
    margin-bottom:1em;
    h4 {
         border-bottom: 1px solid #f6f6f6;
         padding-bottom: .25em;
    }
}

.react-select-container, .react-select__control {
    border: 1px solid #eaeaea !important;
}
.add-new-material.form-check {
    padding: 0;
}
.add-new-material [type="radio"] {
    display: none;
}



/*-----------------------
Radio button animation
------------------------*/
@keyframes check-bounce {
    0% {
        -webkit-transform: scale(.7);
        transform: scale(.7);
    }
    30% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    60% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}



/* --------------------------------

Material Listing Styling

-------------------------------- */
table.table-details tr td:nth-child(2) {
    text-align: right;
    color:#333;
}
table.table-details {
    width:80%;
    color:#666;
}



/* --------------------------------

Settings Pages

-------------------------------- */
table.table.table-settings tr td {
    padding: .5em;
    border: none;
}
table.table.table-settings tr td:nth-child(2) {
    font-weight: 600;
}


/* --------------------------------

General Styling

-------------------------------- */

// a { color: $primary; }
// a:hover { color: $primary-active; }

.navbar-light .navbar-nav .nav-link { color: #444;}





/* --------------------------------

Header

-------------------------------- */
nav.primaryNav {
    background: #fff;
    padding: 0 1rem;
    box-shadow: $box-shadow;
    position: inherit;
    z-index: 3;
}
.navbar {
    li.nav-item a {
        padding: 1em;
        border-bottom: 3px solid transparent;
        display: flex;
        align-items: center;
    }
    a.nav-link.active {
        border-color: $primary!important;
        color: $primary!important;
    }
    a.nav-link svg {
        max-width: 16px;
        margin-right: 10px;
    }
    // margin-bottom: 1em;
}

.flex-wrap {
    flex-wrap: wrap;
}

/* --------------------------------

Buttons

-------------------------------- */
.btn-default {
    font-size:14px;
    font-weight: bold;
    text-align: center;
    background: rgb(245, 246, 247);
    padding: 0 12px;
    color: #333;
    border-radius: .35em;
}
.btn-default:hover {
    background: rgb(235, 237, 240);
}
.btn-default.focus, .btn-default:focus {
    background: rgb(218, 221, 225);
    box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background: rgb(218, 221, 225);
}

.btn {
    padding: .35rem 1.25rem;
    transition: none!important;
    font-weight: 500;
}
.btn-sm {
    padding: .25rem .65rem;
}
.btn-primary {
    background-color: $primary;
    border-color: $primary;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
.btn-primary:hover {
    background-color: $primary-active;
    border-color: $primary-active;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: $primary-active;
    border-color: $primary-active;
}
.btn-primary.focus, .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba($primary, .3);
}

.btn-success {
    color: #fff;
    background-color: $success;
    border-color: $success;
    &.secondary {
        background-color: change-color(($success), $lightness: 97%);
        border-color: change-color(($success), $lightness: 97%);
        color:$success;
    }
}



.status-2 svg circle {
    display: none;
}

.status-2 svg {
    margin: -8px;
    margin-left: -11px;
    max-width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}




.modal {
will-change: transform;
  will-change: opacity;
  transition:.25s ease all!important; 
}

.modal.show .modal-dialog {
    transform: scale(1);
    opacity: 1;
   
}

.modal.fade .modal-dialog {
    transform: scale(.95)!important;
    opacity: 0;
}


.modal.fade.show .modal-dialog {
    transform: scale(1)!important;
    opacity: 1;
}


.btn {
    font-weight: 600;
}
.addMaterialSubmitButton {
    padding:.75em;
    margin:1em 0;
}
.hasError .css-vj8t7z {
    border-color: $error!important;
}
.hasError input {
    border-color: $error!important;
}
.hasError label {
    color: $error;
}
.errorMessageInline {
    color:$error;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 5px 0;
    font-size: .9em;
    svg {
        margin-right:5px;
    }
}

.navbar-brand {
    position: relative;
}

.dropdown-menu-right {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 18px 30px 0px;
    background: rgb(255, 255, 255);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(238, 238, 238);
    border-image: initial;
    border-radius: 5px;
    width: 300px;
}
.dropdown-menu a:first-child {
   border-bottom:1px solid #999;
}

.dropdown-item.active, .dropdown-item:active {
    background: $primary;
}
.navbar a.dropdown-item {
    padding: .5em 1em!important;
}
.navbar a.dropdown-item:first-child {
    padding:1em!important;
}
/* --------------------------------

Advanced Search Sidebar

-------------------------------- */

.custom-control-label {
    width: 100%;
    height: 100%;
    font-weight: normal;
    padding-top: 5px; 
  padding-bottom: 5px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before, .custom-control-input:checked~.custom-control-label::before{
    background-color: $primary;
}

.custom-control-label::before, .custom-control-label::after {
    top: .5rem;
}
.custom-control.custom-checkbox.position-relative.form-check:hover {
    background: #0574d912;
    border-radius: .35em;
    cursor: pointer;
}
.custom-control {
    padding-left:2em;
}
label.custom-control-label:hover {
    cursor: pointer;
}
.css-2o5izw {
    border-color:$primary!important;
    box-shadow: 0 0 0 1px $primary!important;
}
.css-vj8t7z {
    border-color:#e0e0e0!important;
}




/* --------------------------------

Modal Styling

-------------------------------- */
.modal-content {
    border:none;
}
.modal-header {
    border-bottom:none;
}
.modal-body {
    padding: 1.25rem;
    background: $background !important;
    border-bottom-left-radius: .3rem;
    border-bottom-right-radius: .3rem;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 580px;
    }
}

.modal-body .cancelBtn.float-left.btn.btn-link {
    padding-left: .5em;
    padding-right: .5em;
    color: #616161;
}

@media (min-width: 768px) {
    .navbar-expand-md .navbar-collapse {
        flex-grow: initial!important;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-left:0;
        padding-right:0;
       
    }
    .navbar-expand-md .navbar-nav li.nav-item {
        margin-left:.75em;
        margin-right: .75em;
        &:first-child{
            margin-left:0;
        }
        &:last-child{
            margin-right:0;}
    }
}



        .svg-inline--fa.fa-spinner-third {

      -webkit-animation: nprogress-spinner 400ms linear infinite;
      animation: nprogress-spinner 400ms linear infinite;
    }
    
    @-webkit-keyframes nprogress-spinner {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes nprogress-spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .rotate-animate-svg{
        transition: .2s ease transform;
    } 
    .chevron-open {
        transform: rotate(0);
    }
    .chevron-open {
        transform: rotate(180deg);
    }